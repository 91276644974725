// Lists and maps

@each $name, $color in $colors {
  .has-text-#{$name} {
    color: $color !important;
  }
  .has-background-#{$name} {
    background-color: $color !important;
  }
  .has-background-hover-#{$name} {
    &:hover {
      background-color: $color !important;
    }

    &.is-selected, &.is-active {
      background-color: $color !important;
    }
  }
  .has-border-#{$name} {
    border-color: $color !important;
  }
}


.has-text-weight-normal {
  font-weight: $weight-normal !important;
}

.has-text-weight-medium {
  font-weight: $weight-medium !important;
}

.has-text-weight-semibold {
  font-weight: $weight-semibold !important;
}

.has-text-weight-bold {
  font-weight: $weight-bold !important;
}