@import "../../../assets/scss/variables";
.products-detail-filter {
  border: 2px solid $active-color;
}
.products-detail-filter .radio-container input:checked ~ .radio-btn {
  background-color: $active-color;
}
.products-detail-filter .radio-container input:checked ~ .radio-text {
  color: #ffffff !important;
}

.checked {
  .radio-btn {
    background-color: $active-color !important;
  }
  .radio-text {
    color: #ffffff !important;
  }
}

.radio-text {
  color: $active-color !important;
}

.model-ttl {
  padding: 0 2rem;
}

//.nice__single-value {
//  color: #CCB !important;
//}
.action-cell {
  display: flex !important;
}

.inner-container {
  .form-field .label-wrapper input{
    color: #8897A2;
  }
  .detail-settings form .form-field input {
    color: #8897A2;
  }
}
.detail-settings {
  .inner-container {
    .custom-input {
      width: 100%;
      min-width: 129px;
      margin: 0 1.9375rem 0 0;
      .text {
        margin-bottom: 0.4375rem !important;
        color: #565656;
        margin: 0;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 400;
      }
      .custom-field {
        margin-top: 0;
        &>div {
          color: #565656;
        }
      }
    }
  }

}

.model-meta {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .draft-status {
    font-size: 16px;
    font-weight: bold;
    padding: 0 2rem;
  }
}
