@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/functions";

.iconsContainer {
  @include flex-center;
  margin: rem-calc(0 0 0 15);

  .iconWrapper {
    width: rem-calc(20);
    height: rem-calc(20);
  }
}
