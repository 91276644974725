input {
  @include clear-btn-input;
}

label {
  @include flex-center;
  display: inline-flex;
}

.data-search {
  .field-holder {
    position: relative;
  }

  input[type="text"], input[type="search"] {
    min-width: 320px;
    padding: rem-calc(8 11.5 8 40.5);
    border: 1px solid $border-color;
    border-radius: rem-calc(3);
    background-color: $white;
    line-height: rem-calc(24);
    color: rgba(21, 41, 53, 0.7);

    &::placeholder {
      line-height: rem-calc(24);
      color: rgba(21, 41, 53, 0.7);
    }

    &:focus {
      & + label {
        .icon {
          fill: $icon-gray;
        }
      }
    }
  }

  label {
    position: absolute;
    left: rem-calc(9.5);
    top: 50%;
    transform: translate(0, -50%);
    width: auto;
    max-width: rem-calc(22);
    height: rem-calc(22);

    .icon {
      fill: $icon-gray-light;
      font-size: rem-calc(22);
    }
  }
}

.products-filter {
  @include flex-center-left;

  $radio-btn-size: rem-calc(16);

  .radio-container {
    @include flex-center-left;
    position: relative;
    min-width: $radio-btn-size + rem-calc(6);
    padding-left: $radio-btn-size + rem-calc(11.25);
    cursor: pointer;
    user-select: none;

    &:not(:last-of-type) {
      margin-right: rem-calc(22);
    }

    &:hover {
      input {
        & ~ .radio-btn {
          background-color: $white;

          &::after {
            opacity: 0.5;
          }
        }
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        & ~ .radio-btn {
          background-color: $white;

          &::after {
            opacity: 1;
          }
        }
      }
    }

    .radio-btn {
      &::after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $radio-btn-size / 2;
        height: $radio-btn-size / 2;
        border-radius: 50%;
        background: $black;
      }
    }
  }

  .radio-btn {
    position: absolute;
    top: 0;
    left: 0;
    height: $radio-btn-size;
    width: $radio-btn-size;
    border: 1px solid $black;
    border-radius: 50%;
    transition: background-color $animation-speed;

    &::after {
      content: "";
      position: absolute;
      opacity: 0;
      transition: opacity $animation-speed;
    }
  }

  .radio-text {
    font-size: rem-calc(14);
    line-height: rem-calc(18);
    color: $active-color2;
    opacity: 0.92;
  }
}

.products-detail-filter {
  @include flex-center-left;
  margin: rem-calc(0 32 0 0);
  border: 2px solid $icon-gray;
  border-radius: 3px;

  .radio-container {
    @include flex-center;
    position: relative;
    padding: rem-calc(5);
    min-width: 146px;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        & ~ .radio-btn {
          background-color: $icon-gray;
        }

        & ~ .radio-text {
          color: $white;
        }
      }
    }

    .radio-btn {
      @include flex-center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      transition: background-color $animation-speed;
      z-index: 1;
    }

    .radio-text {
      position: relative;
      z-index: 2;
      background-color: transparent;
      color: $text-color;
      transition: color $animation-speed;
    }
  }
}

$checkbox-btn-size: rem-calc(16);

.checkbox-container {
  @include flex-center-left;
  position: relative;
  min-width: $checkbox-btn-size + rem-calc(6);
  padding-left: $checkbox-btn-size + rem-calc(11.25);
  cursor: pointer;
  user-select: none;

  &:hover {
    input {
      & ~ .checkbox-btn {
        background-color: $white;

        &::after {
          opacity: 0.5;
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      & ~ .checkbox-btn {
        background-color: $white;

        &::after {
          opacity: 1;
        }
      }
    }
  }
}

.checkbox-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: $checkbox-btn-size;
  width: $checkbox-btn-size;
  border-radius: 2px;
  border: 1px solid $black;
  transition: background-color $animation-speed;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $checkbox-btn-size;
    height: $checkbox-btn-size;
    border-radius: 2px;
    background: url("../../icons/icon-check-mark.svg") center no-repeat $dark;
    opacity: 0;
    transition: opacity $animation-speed;
  }
}

.checkbox-text {
  font-size: rem-calc(14);
  line-height: rem-calc(18);
  color: $active-color2;
  opacity: 0.92;
}