@keyframes tableBorderStyleDropdownSlideUp {
  from {
    opacity: 0;
    margin-top: 20px;
  }
  to {
    opacity: 1;
    margin-top: 10px;
  }
}

.border-color-cta {

  .dropdown {
    position: absolute;
    background: #FFF;
    z-index: 9999999;
    list-style: none;
    margin-left: -5px;
    border-radius: 4px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);
    margin-top: 10px;
    opacity: 0;
    padding: 4px;
    visibility: hidden;

    :after {
      position: absolute;
      width: 24px;
      height: 10px;
      background: transparent;
      content: "";
      top: -10px;
      left: 0;
    }

    :before {
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      content: "";
      background: #FFF;
      top: -5px;
      left: 10px;
      position: absolute;
      z-index: -1;
    }

    .colors {
      max-width: 140px;

      .color {
        padding: 2px;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        float: left;
        margin: 4px;

        .dot {
          cursor: pointer;
          width: 16px;
          height: 16px;
          border-radius: 16px;
          box-sizing: content-box;
        }

        &:hover {
          padding: 0;

          .dot {
            border: 2px solid #ADDEFF;
          }
        }
      }
    }
  }

  &.open {
    color: #2571DB;
    background: #D6F0FF;

    .dropdown {
      animation: ease-in .1s tableBorderStyleDropdownSlideUp;
      visibility: visible;
      opacity: 1;
    }
  }
}