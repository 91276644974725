.extract-element {
  padding: 20px 20px 20px;
  border-bottom: 1px solid #CCC;

  &:nth-child(even) {
    background: #F5F5F5
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    margin-bottom: 20px;
    border-bottom: 0;
  }

  .extract-header {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;

    .extract-delete {
      display: inline-block;
      height: 21px;
      vertical-align: middle;
      margin-left: 8px;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .extract-body {
    display: flex;
    flex-direction: column;

    .extract-var {
      padding-right: 20px;
      width: 70%;
    }

    .extract-providers {
      display: flex;
      align-content: space-between;

      .extract-row {
        flex: 1 1 0;
        padding: 10px;

        &:first-child{
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.add-extract {
  text-align: right;
}

.extract-variable-select {
  display: flex;

  .extract-variable-level {
    margin-right: 8px;
    color: #666;
  }

  .extract-variable-label {
    font-weight: 500;

    span.variable-type {
      background: #EEE;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 0 5px;
      font-weight: bold;
    }

    span.variable-id {
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 0 5px;
      font-weight: 700;
      color: #AAA
    }

    .iconWrapper {
      position: relative;
      float: left;
      width: 24px !important;
      height: 24px !important;

      svg {
        fill: #000;
        width: 24px !important;
        height: 24px !important;
        top: 5px;
        left: 3px;
        position: absolute;

        path {
          fill: #000
        }
      }
    }
  }
}

.extract-option-select {
  display: flex;

  .extract-option-level {
    margin-right: 8px;
    color: #666;
  }

  .extract-option-label {
    font-weight: 500;

    span.option-type {
      background: #EEE;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 0 5px;
      font-weight: bold;
    }

    span.option-id {
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 0 5px;
      font-weight: 700;
      color: #AAA
    }

    span.ico-holder-text {
      font-weight: 900;
      font-size: 16px;
      line-height: 12px;
      width: 24px;
      text-align: center;
      display: inline-block;
      user-select: none;
    }

    .iconWrapper {
      position: relative;
      float: left;
      width: 24px !important;
      height: 24px !important;

      svg {
        fill: #000;
        width: 24px !important;
        height: 24px !important;
        top: 5px;
        left: 3px;
        position: absolute;

        path {
          fill: #000
        }
      }
    }
  }
}

.extract-section {
  position: sticky;
  top: 0
}

.current-value {

  .extract-variable-level,
  .extract-option-level {
    display: none;
  }
}