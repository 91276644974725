ul, ol {
  @include clear-list;
  width: 100%;
}

.data-list-container {
  width: 100%;

  .list-ttl {
    margin: rem-calc(0 0 25);
    font-size: rem-calc(16);
    line-height: rem-calc(25);
    font-weight: $weight-normal;
    color: $menu-color;
  }
}

.data-list {
  border-radius: rem-calc(3 3 0 0);
  background-color: $white;

  li {
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 0 $shadow-color3;
    padding: rem-calc(16 24);
    font-size: rem-calc(16);
    line-height: rem-calc(16);
    color: $menu-color;
    button {
      //align-self: flex-end;
    }
  }
}

.detail-links-container {

  .links-list-holder {
    padding: rem-calc(32 20 38);
    margin: rem-calc(0 0 20);
    border-radius: rem-calc(3);
    background-color: $white;
    box-shadow: inset 0 -1px 0 0 $shadow-color;
  }

  .links-ttl {
    margin: rem-calc(0 0 24 24);
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    font-weight: $weight-bold;
    color: $text-color;
  }

  .links-list {
    li {
      &:not(:last-of-type) {
        margin: rem-calc(0 0 8);
      }
    }

    .text {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
      color: $text-color;
    }

    .iconWrapper {
      width: rem-calc(16);
      height: rem-calc(16);
      margin: rem-calc(0 8 0 0);
    }

    .icon {
      font-size: rem-calc(14);
    }
  }

  .btn-wrapper {
    justify-content: flex-start;

    .btn {
      padding: rem-calc(4 4 4 0);

      .text {
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        color: $text-color;
      }

      .iconWrapper {
        width: rem-calc(16);
        height: rem-calc(16);
        margin: rem-calc(0 8 0 0);
      }

      .icon {
        font-size: rem-calc(14);
      }
    }
  }

  .text {
    transition: color $animation-speed;
  }

  a, button {
    &:hover {
      .text {
        color: $active-color !important;
      }

      .icon {
        fill: $active-color;
      }
    }
  }
}
