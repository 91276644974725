@keyframes tableDropdownSlideUp {
  from {
    opacity: 0;
    margin-top: 20px;
  }
  to {
    opacity: 1;
    margin-top: 10px;
  }
}

.table-block-cta {

  .dropdown {
    position: absolute;
    background: #FFF;
    z-index: 9999999;
    list-style: none;
    margin-left: -5px;
    border-radius: 4px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);
    margin-top: 10px;
    opacity: 0;
    padding: 4px;
    visibility: hidden;

    :after {
      position: absolute;
      width: 24px;
      height: 10px;
      background: transparent;
      content: "";
      top: -10px;
      left: 0;
    }

    :before {
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      content: "";
      background: #FFF;
      top: -5px;
      left: 10px;
      position: absolute;
      z-index: -1;
    }

    .table {
      .row {
        display: flex;
        
        .column {
          box-sizing: border-box;
          border: 1px #D1D9DF solid;
          background: #F9FAFA;
          width: 14px;
          height: 14px;
          margin: 1px;
          cursor: pointer;

          &.active {
            border-color: #84C9FF;
            background: #D6F0FF;
          }
        }
      }
    }

    .table-size {
      line-height: 24px;
      font-weight: 900;
      font-size: 12px;
      text-align: center;
      color: #666;
    }
  }

  &.open {
    color: #2571DB;
    background: #D6F0FF;

    .dropdown {
      animation: ease-in .1s tableDropdownSlideUp;
      visibility: visible;
      opacity: 1;
    }
  }
}