@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.eot');
  src: local('Inter Regular'), local('Inter-Regular'),
  url('../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Regular.woff2') format('woff2'),
  url('../fonts/Inter-Regular.woff') format('woff'),
  url('../fonts/Inter-Regular.ttf') format('truetype'),
  url('../fonts/Inter-Regular.svg#Inter-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter - Medium';
  src: url('../fonts/Inter-Medium.eot');
  src: local('Inter Medium'), local('Inter-Medium'),
  url('../fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Medium.woff2') format('woff2'),
  url('../fonts/Inter-Medium.woff') format('woff'),
  url('../fonts/Inter-Medium.ttf') format('truetype'),
  url('../fonts/Inter-Medium.svg#Inter-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter - Semi Bold';
  src: url('../fonts/Inter-SemiBold.eot');
  src: local('Inter Semi Bold'), local('Inter-SemiBold'),
  url('../fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-SemiBold.woff2') format('woff2'),
  url('../fonts/Inter-SemiBold.woff') format('woff'),
  url('../fonts/Inter-SemiBold.ttf') format('truetype'),
  url('../fonts/Inter-SemiBold.svg#Inter-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter - Bold';
  src: url('../fonts/Inter-Bold.eot');
  src: local('Inter Bold'), local('Inter-Bold'),
  url('../fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Bold.woff2') format('woff2'),
  url('../fonts/Inter-Bold.woff') format('woff'),
  url('../fonts/Inter-Bold.ttf') format('truetype'),
  url('../fonts/Inter-Bold.svg#Inter-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url('../fonts/Montserrat-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Circular - Medium';
  src: url('../fonts/Montserrat-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Circular - Bold';
  src: url('../fonts/Montserrat-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

