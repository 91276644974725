@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/functions";

.conditions-drawer {
  padding: rem-calc(150 56);
  text-align: center;
  min-width: rem-calc(705);
  position: relative;
  height: 100%;

  .condition-wrapper {

    .condition-holder {
      display: flex;
      position: relative;
      //padding-left: 92px;
      padding-bottom: 28px;

      .condition-meta {
        text-align: left;
        .condition-section {
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          color: #868E96;
        }
        .condition-label {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          max-width: 200px;
        }
      }
      .nice__control {
        border: none;
        width: 110px;
        .nice__single-value {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #1774FF;
        }
      }
      .nice__menu {
        text-align: left;
        &>div {
          text-align: left;
        }
      }
    }


  }

  .inner-wrapper {
    //padding-left: 94px;
    position: relative;
    top: -46px;
    left: -24px;
    min-height: 110px;

    //.condition-block:first-child {
    //  .add-item {
    //    display: none;
    //  }
    //}

    .nice__single-value {
      color: #1774FF !important;
    }

    .condition-wrapper {
      padding-bottom: 28px;
      padding-top: 27px;
    }
  }


  .grey-icon {
    span {
      color: #BDC4CB !important;
    }
  }

  > div {
    width: 100%;
  }

  h2 {
    margin-bottom: rem-calc(50);
  }

  span {
    color: $blue;
  }

  p {
    margin-bottom: 350px;
  }

  .buttons-holder {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    padding-bottom: 80px;
  }

  .cancel {
    @include animate(border-color);
    height: rem-calc(48);
    width: rem-calc(104);
    color: $ttl-color;
    font-size:rem-calc(18);
    font-weight: 500;
    line-height: rem-calc(21);
    background-color: $white;
    border: rem-calc(1) solid transparent;
    border-radius: rem-calc(5);

    &:hover {
      border-color: $pink-100;
    }
  }

  .save {
    @include animate(background-color);
    height: rem-calc(48);
    width: rem-calc(104);
    border-radius: rem-calc(3);
    background-color: $pink;
    box-shadow: inset rem-calc(0 -1 0 0) $pink-100;
    color: $white;
    font-size: rem-calc(18);
    font-weight: 500;
    line-height: rem-calc(24);

    &:hover {
      background-color: darken($pink, 10%);
    }
  }
  .can-toggle {
    display: flex;
    flex-direction: column;
    width: 92px;
    z-index: 0;
    //&:before {
    //  content: '';
    //  position: absolute;
    //  top: -37px;
    //  left: 43px;
    //  width: 48px;
    //  height: 36px;
    //  background: #FFFFFF;
    //  border-left: 1px solid #DEE2E6;
    //  border-top: 1px solid #DEE2E6;
    //  border-top-right-radius: 0;
    //  border-top-left-radius: 4px;
    //  //border: 1px solid #DEE2E6;
    //  box-sizing: border-box;
    ////}
    &:after {
      content: '';
      position: absolute;
      top: 29px;
      left: 44px;
      width: 48px;
      height: calc(100% - 55px);
      //background: #FFFFFF;
      border-left: 1px solid #DEE2E6;
      //border-top: 1px solid #DEE2E6;
      //border-top-right-radius: 0;
      //border-top-left-radius: 4px;
      box-sizing: border-box;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
    label {
      justify-content: start;
    }
  }
  .add-item {
    position: absolute;
    //new
    bottom: 6px;
    left: -3px;
    padding: 25px 40px 7px 40px;
    // new
    //bottom: 11px;
    //left: 37px;
    z-index: 2;
    &:hover {
      .add-item {
        z-index: 0;
      }
      .condition-popup {
        display: block;
        opacity: 1;
        z-index: 2;
      }
    }
    .plus {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      border: 1px solid $blue;
      background: #FFFFFF;
      color: $blue;
      line-height: 1px;
      font-size: rem-calc(10);
      cursor: pointer;
      padding: 0;
    }
    .condition-popup {
      position: absolute;
      display: none;
      background: #fff;
      //new
      top: -32px;
      left: 39px;

      //new
      //top: -50px;
      //left: -5px;
      text-align: left;
      background-color: #fff;
      padding: 5px 10px;
      border-radius: 4px;
      opacity: 0;
      -webkit-box-shadow: 0px 0px 5px 2px rgba(191,191,191,1);
      -moz-box-shadow: 0px 0px 5px 2px rgba(191,191,191,1);
      box-shadow: 0px 0px 5px 2px rgba(191,191,191,1);

      button {
        //background-color: #f8f9fa;
        width: 100%;
        color: $blue;
        background: #fff;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        &:hover {
          background: #F6F9FC;
          border-radius: 2px;
        }
      }
    }
  }
}

.border-top {
  align-self: flex-end;
  width: 48px;
  height: 36px;
  background: #FFFFFF;
  border-left: 1px solid #DEE2E6;
  border-top: 1px solid #DEE2E6;
  border-top-right-radius: 0;
  border-top-left-radius: 4px;
  //border: 1px solid #DEE2E6;
  box-sizing: border-box;
}
//.border-bottom {
//  align-self: flex-end;
//  width: 48px;
//  height: 67px;
//  background: #FFFFFF;
//  border-left: 1px solid #DEE2E6;
//  border-top: 1px solid #DEE2E6;
//  border-top-right-radius: 0;
//  border-top-left-radius: 4px;
//  box-sizing: border-box;
//  transform: matrix(1, 0, 0, -1, 0, 0);
//}

.condition-block {
  display: flex;
  position: relative;
  &:not(:first-child) {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: -48px;
      width: 48px;
      height: 50px;
      background: #FFFFFF;
      border-left: 1px solid #DEE2E6;
      border-top: 1px solid #DEE2E6;
      border-top-right-radius: 0;
      border-top-left-radius: 4px;
      box-sizing: border-box;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

}

.inner-condition {
  position: relative;
  padding-bottom: 34px;
  //&:before {
  //  position: absolute;
  //  content: '';
  //  top: 0;
  //  left: -48px;
  //  width: 48px;
  //  height: 50px;
  //  background: #FFFFFF;
  //  border-left: 1px solid #DEE2E6;
  //  border-top: 1px solid #DEE2E6;
  //  border-top-right-radius: 0;
  //  border-top-left-radius: 4px;
  //  box-sizing: border-box;
  //  transform: matrix(1, 0, 0, -1, 0, 0);
  //}
}
.multiple-icon-wrapper {
  padding: 12px;

}
.multiple-icon {
  background: url("../../../../assets/images/multiple.png") 50% 50% no-repeat;
  height: 25px;
  width: 25px;
  background-size: contain;
}

.delete-node-button {
  position: absolute;
  left: 10px;
  top: 27px;
  cursor: pointer;
  z-index: 1;
}
.delete-option {
  left: 10px;
  top: 0px;
}

.condition-content-block {
  position: absolute;
}

@media (max-width: 1280px) {
  .conditions-drawer {
    width: rem-calc(500);
    //max-width: rem-calc(500);
    //overflow: auto;
    overflow-x: scroll;
    overflow-y: scroll;
    min-width: rem-calc(500);
  }
}
.helper-one {
  position: absolute;
  left: 100px;
  bottom: 20%;
  .plus-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 1px solid $blue;
    color: $blue;
    line-height: 1px;
    font-size: rem-calc(10);
    cursor: pointer;
    padding: 0;
  }
}
.helper-two {
  position: absolute;
  left: 100px;
  bottom: 20%;
  button {
    display: inline-flex;
  }
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 10px;
}

