.dialog-inner {
  position: relative;
  width: 672px;
  height: 520px;
  background: $white;

  .dialog-close {
    @include clear-btn-input;
    padding: 0;
    position: absolute;
    top: rem-calc(7);
    right: rem-calc(9);

    .iconWrapper {
      transform: rotate(45deg);
    }

    .icon {
      font-size: rem-calc(16);
    }
  }

  .dialog-content {
    padding: rem-calc(16 32);
    height: 100%;

    > .content {
      padding: rem-calc(56 56 32);
    }
  }

  .ttl-container {
    @include flex-center-left;
    flex-flow: column;
    color: $text-color;

    .ttl, .sub-ttl {
      margin: 0;
      font-weight: $weight-normal;
    }

    .ttl {
      margin: rem-calc(0 0 5);
      font-size: rem-calc(28);
      line-height: rem-calc(42);
    }

    .sub-ttl {
      font-size: rem-calc(12);
      line-height: rem-calc(18);
      opacity: 0.45;
    }
  }

  .modal-form-container {
    max-width: 320px;
    padding-top: 12%;
    margin: 0 auto;

    .grid-container {
      .grid-item {
        @include flex-center-sb;

        .form-field {
          width: calc((100% - 32px) / 2);
        }
      }

      .grid-item-row {
        .form-field {
          width: 100%;
        }
      }
    }

    div.ReactTags__tags {
      position: relative;
    }

    /* Styles for the input */
    div.ReactTags__tagInput {
      width: 200px;
      border-radius: 2px;
      display: inline-block;
    }
    div.ReactTags__tagInput input.ReactTags__tagInputField,
    div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
      height: 31px;
      margin: 0;
      font-size: 12px;
      width: 100%;
      border: 1px solid #eee;
      padding: 0 4px;
    }

    /* Styles for selected tags */
    div.ReactTags__selected span.ReactTags__tag {
      border: 1px solid #ddd;
      background: #eee;
      font-size: 12px;
      display: inline-block;
      padding: 5px;
      margin: 0 5px;
      cursor: move;
      border-radius: 2px;
    }
    div.ReactTags__selected a.ReactTags__remove {
      color: #aaa;
      margin-left: 5px;
      cursor: pointer;
    }

    /* Styles for suggestions */
    div.ReactTags__suggestions {
      position: absolute;
    }
    div.ReactTags__suggestions ul {
      list-style-type: none;
      box-shadow: .05em .01em .5em rgba(0,0,0,.2);
      background: white;
      width: 200px;
    }
    div.ReactTags__suggestions li {
      border-bottom: 1px solid #ddd;
      padding: 5px 10px;
      margin: 0;
    }
    div.ReactTags__suggestions li mark {
      text-decoration: underline;
      background: none;
      font-weight: 600;
    }
    div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
      background: #b7cfe0;
      cursor: pointer;
    }
  }

  .add-user-form {
    max-width: initial;
    padding-top: 5.5%;

    .footer {
      margin-top: rem-calc(20);
    }

  }

  .footer {
    @include flex-start-center;
    margin-top: rem-calc(30);

    .btn {
      padding: rem-calc(12 28);
    }
  }
}

.confirm {
  @include flex-center;
  flex-flow: column;

  .btn-wrapper {
    flex: 1;
    @include flex-center;
  }

  .btn {
    margin: rem-calc(0 30);
  }
}
