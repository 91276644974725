
.modal-root {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  touch-action: none;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999999;

  .modal-container {
    width: 600px;
    background-color: #FFFFFF;
    margin: 48px;
    display: flex;
    position: relative;
    flex-direction: column;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    border-radius: 4px;

    .modal-title {
      padding:  24px;
      font-size: 18px;
      background: #F6F9FC;
      color: #2A3A57;
      text-align: center;
      font-weight: bold;
      user-select: none;
      border-radius: 4px 4px 0 0;
    }

    .modal-content {
      padding: 24px;

      & + .modal-controls {
        padding-top: 0;
      }
    }

    .modal-controls {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding:  24px;

      button {
        user-select: none;
        margin-left: 16px;
      }
    }
  }
}

