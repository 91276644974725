@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/functions";

button {
  border: none;
  outline: none;
  //background-color: transparent;
  padding: 12px 24px;
  border-radius: 4px;
  &:disabled {
    opacity: 0.5;
  }


  &.btn {
    @include clear-btn-input;
    @include flex-center;
    padding: rem-calc(8 16);
    border-radius: rem-calc(3);
    transition: color $animation-speed, background-color $animation-speed, box-shadow $animation-speed, border-color $animation-speed, opacity $animation-speed;
  }

  &.btn-pink {
    @include animate(background-color);
    font-size: rem-calc(17);
    padding: rem-calc(10 20);
    background-color: $pink;
    margin: rem-calc(0 3);
    color: $white;
    font-weight: 500;
    box-shadow: inset 0 -1px 0 $pink-100;
    border-radius: rem-calc(4);

    &:hover {
      background-color: darken($pink, 10%);
    }
  }

  &.btn-blue {
    @include animate(background-color);
    @include flex-center;
    font-size: rem-calc(17);
    padding: rem-calc(10 20);
    background-color: $blue;
    margin: rem-calc(0 3);
    color: $white;
    font-weight: 500;
    border-radius: rem-calc(2);



    &.pink {
      background-color: $pink;

      &:hover {
        background-color: darken($pink, 10%);
      }
    }



    &:hover {
      background-color: darken($blue, 10%);
    }
  }

  &.btn-white {
    //@include animate(border-color color);
    font-size: rem-calc(14);
    color: $black;
    font-weight: 700;
    background-color: $white;
    //border: rem-calc(1) solid $gray-700;
    border-radius: rem-calc(3);
    padding: rem-calc(10 13);

    //&:hover {
    //  color: $pink;
    //  border-color: $pink;
    //}
  }

  &.btn-dark {
    background: $dark;
    color: $white;

    &:hover {
      background: $bg-main;
      color: $dark;

      .icon {
        fill: $dark;
      }
    }

    .icon {
      fill: $white;
    }
  }

  &.btn-gary {
    background: $bg-main;
    color: $dark;

    &:hover {
      background: $dark;
      color: $white;

      .icon {
        fill: $white;
      }
    }

    .icon {
      fill: $dark;
    }
  }

  &.btn-active {
    background-color: $active-color2;
    color: $white;
    box-shadow: inset 0 -1px 0 0 $shadow-color-active;
    font-weight: $weight-medium;
    min-height: 42px;

    &:hover {
      background-color: $active-color2;
      color: $dark;

      svg {
        fill: $dark;
      }
    }

    .icon-wrapper {
      width: rem-calc(24);
      height: rem-calc(24);
    }

    svg {
      fill: $white;
    }
  }

  &.btn-profile {
    padding: rem-calc(8 13);
  }

  &.btn-tag {
    padding: rem-calc(4.5 8.5);
    border: 1px solid $border-color;
    border-radius: rem-calc(14.5);
    background-color: $bg-gray3;
  }

  &.btn-icon {
    @include animate(opacity);
    position: relative;
    //text-indent: -9999px;
    @include flex-center;
    width: rem-calc(24);
    height: rem-calc(24);
    padding: 0;
    border: none;
    background-color: transparent;
    color: transparent;

    &::before {
      content: "";
      @include abs-center;
      max-width: rem-calc(12);
      max-height: rem-calc(12);
      width: rem-calc(12);
      height: rem-calc(12);
    }

    &:hover {
      opacity: 0.7;
    }

    &.is-active, .is-hovered {
      opacity: 0.7;
    }

    .iconWrapper {
      width: auto !important;
      height: auto !important;

      .multiple-ico,
      .del-ico {
        font-size: rem-calc(35);
        margin-bottom: rem-calc(-41);
        margin-left: rem-calc(5);
      }
    }

    //&.btn-download {
    //  &::before {
    //    background: url("../icons/icon-download.svg") center / contain no-repeat;
    //  }
    //}
    //
    //&.btn-edit {
    //  &::before {
    //    background: url("../icons/icon-edit.svg") center / contain no-repeat;
    //  }
    //}
    //
    //&.btn-delete {
    //  &::before {
    //    background: url("../icons/icon-delete.svg") center / contain no-repeat;
    //  }
    //}
  }

  &.btn-icon-text {
    padding: rem-calc(6 8);
    font-size: rem-calc(14);
    line-height: rem-calc(18);
    font-weight: $weight-bold;

    .icon {
      margin-right: rem-calc(5);
    }

    .iconSettings {
      font-size: rem-calc(13);
    }
  }

  &.btn-list {
    justify-content: flex-start;
    width: 100%;
    padding: rem-calc(12 15);
    background-color: $white;
    border-radius: rem-calc(0 0 3 3);
    box-shadow: inset 0 -1px 0 0 $shadow-color3;
    font-size: rem-calc(16);
    line-height: rem-calc(16);
    color: $menu-color;

    .text, .icon {
      opacity: 0.42;
    }

    .text {
      font-size: inherit;
      line-height: inherit;
    }

    .icon {
      margin-left: rem-calc(3);
      fill: $menu-color;
      font-size: rem-calc(8);

      &.eye-ico {
        font-size: 30px;
        opacity: 1;
      }
    }
  }

  &.btn-send {
    font-size: rem-calc(16);
    line-height: rem-calc(25);
  }

  &.btn-double-padding-side {
    padding: rem-calc(8 32);
  }

  &[disabled], &.btn-disable {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;

    &:hover {
      background-color: inherit;
      color: inherit;
      box-shadow: inherit;
      border-color: inherit;
      opacity: inherit;
    }
  }

  &.btn-link-eye {
    //background-color: transparent;
    //color: $black;
    position: relative;
    padding-left: 25px;
    padding-right: 5px;
    margin-right: rem-calc(8);
    font-size: rem-calc(14);
    text-transform: none;

    .iconWrapper {
      width: auto !important;
      height: auto !important;
    }

    .eye-ico {
      font-size: rem-calc(33);
      position: absolute;
      left: rem-calc(4);
      top: rem-calc(12);
    }

  }
  &.btn-link-save {
    position: relative;
    padding-left: 41px;
    padding-right: 21px;
    margin-right: rem-calc(8);
    font-size: rem-calc(14);
    text-transform: none;

    .save-ico {
      font-size: rem-calc(33);
      position: absolute;
      top: 8px;
      left: 15px;
    }
  }

  &.btn-add {
    @include animate(opacity);
    background-color: transparent;
    font-size: rem-calc(15);
    border: rem-calc(2) solid $blue;
    border-radius: rem-calc(3);
    padding: rem-calc(8 30);
    font-weight: 600;
    color: $blue;

    &:hover {
      opacity: .7;
    }
  }

  &.btn-cancel {
    @include animate(opacity);
    background-color: $icon-gray;
    padding: rem-calc(11 30);
    color: $white;
    font-size: rem-calc(15);
    font-weight: 500;
    border-radius: rem-calc(3);

    &:hover {
      opacity: .7;
    }
  }

  &.btn-save {
    @include animate(opacity);
    padding: rem-calc(11 30);
    color: $white;
    font-size: rem-calc(15);
    font-weight: 500;
    border-radius: rem-calc(3);
    background-color: $green-200;
    box-shadow: inset rem-calc(0 -1 0) $green-300;

    &:hover {
      opacity: .7;
    }
  }
}

.btn-white {
  @include animate(border-color color);
  font-size: rem-calc(14);
  color: $black;
  font-weight: 700;
  background-color: $white;
  //border: rem-calc(1) solid $gray-700;
  border-radius: rem-calc(3);
  padding: rem-calc(7 15 8);

  &:hover {
    color: $pink;
    border-color: $pink;
  }
}


.sso-button {
  color: #F6F9FC
}

.primary {
  background: $blue !important;
  color: #FFFFFF !important;
  &:hover {
    //background-color: $dark-blue !important;
  }
  &:active {
    background-color: $dark-blue !important;
  }
}

.secondary {
  color: #3392FF !important;
  background: transparent !important;
  font-weight: 600;
  font-size: 16px;
  &:hover {
    background: #F6F9FC !important;
  }
  &:active {
    color: #2571DB !important;
    //font-weight: 600;
    //font-size: 16px;
  }
}


.gray {
  color: #333 !important;
  background: transparent !important;
  &:hover {
    background: #F6F9FC !important;
  }
  &:active {
    background: #F6F9FC !important;
  }
}
