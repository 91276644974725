@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/functions";

.navContainer {
  position: absolute;
  left: 0;
  top: 0;
  @include flex-start-sb;
  flex-flow: column;
  width: 264px;
  height: 100%;
  box-shadow: inset 0 -1px 0 $shadow-color3, inset -1px 0 0 $shadow-color2;
  background-color: $white;

  nav {
    width: 100%;
  }
}

.mainMenu {
  .link {
    @include flex-center-left;
    padding: rem-calc(12 27);
    box-shadow: inset 0 -1px 0 $shadow-color3, inset -1px 0 0 $shadow-color2;
    transition: background-color $animation-speed, color $animation-speed;

    &:hover, &.active {
      background-color: $active-color;
      color: $white;

      .icon {
        fill: $white;
      }
    }

    .icon {
      fill: $active-color;
    }
  }

  .iconWrapper {
    margin: rem-calc(0 5 0 0);
    width: rem-calc(32);
    height: rem-calc(32);
  }

  .text {
    margin: 0;
    font-size: rem-calc(16);
    line-height: rem-calc(25);
    letter-spacing: rem-calc(-0.8);
    font-weight: $weight-semibold;
  }
}

footer {
  width: calc(100% - 1px);
  background-color: $bg-gray2;
}

.footerMenu {
  .link {
    position: relative;
    @include flex-center;
    opacity: 0.6;
    font-size: rem-calc(11);
    line-height: rem-calc(17);
    font-weight: $weight-normal;
    color: $menu-color;
  }
}