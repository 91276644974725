@import "normalize";
@import "fonts";
@import "variables";
@import "color_list";
@import "functions";
@import "mixins";
@import "helpers";
@import "elements/index";
@import "grid/index";

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  -webkit-tap-highlight-color: transparent !important;
}

html {
  background-color: $white;
  font-size: $default-font-size;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 320px;
  text-size-adjust: 100%;
}

html, body {
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
button,
input,
select,
textarea {
  font-family: $default-font-family;
}

body {
  background-color: $white;
  color: $text-color;
  font-size: 1rem;
  font-weight: $weight-normal;
  line-height: $default-line-height;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

button, a, .link {
  cursor: pointer;
  
  &:hover {
    text-decoration: none !important;
  }
}

a, .link {
  color: $menu-color;
  cursor: pointer;
  text-decoration: none;
  transition: color $animation-speed ease-in-out;

  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* prevent tap highlight color / shadow */
  user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */

  &:hover {
    color: $active-color;
  }
}

hr {
  background-color: $bg-gray2;
  border: none;
  display: block;
  height: 1em;
  margin: 1em;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
input[type='date'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: 0.875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  font-weight: $weight-bold;
}

p, .h4 {
  font-size: rem-calc(16);
  line-height: rem-calc(25);
  letter-spacing: rem-calc(-0.8);
}

h4 {
  font-weight: $weight-semibold;
}

.db {
  display: block !important;
}

.inline-flex {
  display: inline-flex !important;
}

.ff-wrap {
  flex-flow: wrap !important;
}

.ff-nowrap {
  flex-flow: nowrap !important;
}

.flex-row {
  display: flex;
  flex-flow: row;
  flex-basis: 100%;
}

.flex-coll {
  display: flex;
  flex-flow: column;
}

.flex-center {
  @include flex-center;
}

.flex-center-sb {
  @include flex-center-sb;
}

.jc-end {
  justify-content: flex-end !important;
}

.jc-start {
  justify-content: flex-start !important;
}

.jc-center {
  justify-content: flex-start !important;
}

.as-end {
  align-self: flex-end !important;
}

.as-center {
  align-self: center;
}

.as-start {
  align-self: flex-start !important;
}

.ma {
  margin: 0 auto !important;
}

.nomb {
  margin-bottom: 0 !important;
}

.nomt {
  margin-top: 0 !important;
}

.noml {
  margin-left: 0 !important;
}

.nomr {
  margin-right: 0 !important;
}

.nom {
  margin: 0 !important;
}

.nopb {
  padding-bottom: 0 !important;
}

.nopt {
  padding-top: 0 !important;
}

.nopl {
  padding-left: 0 !important;
}

.nopr {
  padding-right: 0 !important;
}

.nop {
  padding: 0 !important;
}

.text-center {
  text-align: center !important;
}

//
//
///* EDITOR */
//
//.input input, .input textarea, .input select {
//  border-radius: 0;
//  border: none;
//  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
//  height: 40px;
//  width: 100%;
//  box-sizing: border-box;
//  background: #FFF;
//}
//
//[class^="icon-"], [class*=" icon-"] {
//  font-family: 'icomoon';
//  speak: none;
//  font-style: normal;
//  font-weight: normal;
//  font-variant: normal;
//  text-transform: none;
//  line-height: 1;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//
//.icon-marquee-plus:before {
//  content: "\e602";
//}
//
//.icon-circle-plus:before {
//  content: "\e603";
//}
//
//.icon-menu:before {
//  content: "\e600";
//}
//
//.icon-search:before {
//  content: "\e604";
//}
//
//.icon-settings:before {
//  content: "\e601";
//}
//
//* {
//  margin: 0;
//  padding: 0;
//}
//
//body {
//  min-width: 1024px;
//  background: #F6F6F6;
//}
//
//.page-container {
//  width: 1024px;
//  margin: auto;
//  margin-bottom: 20px;
//}
//
//a {
//  color: #2c374c;
//  text-decoration: none;
//}
//
//a:hover {
//  text-decoration: underline;
//}
//
//.hide {
//  display: none;
//}
//
//header {
//  margin: auto;
//  height: 40px;
//  padding: 15px 5px;
//  background: #fff;
//  box-sizing: initial;
//}
//
//header.black {
//  background: #2c374c;
//  margin-bottom: 20px;
//}
//
//header .container {
//  width: 1024px;
//  margin: auto;
//}
//
////header .logo {
////  width: 125px;
////  height: 40px;
////  background: url(../../img/logo.png) no-repeat;
////  float: left;
////}
////
////header.black .logo {
////  background: url(../../img/logo-white.png) no-repeat;
////}
//
//header .menu {
//  float: right;
//}
//
//header .menu ul {
//  list-style: none;
//}
//
//header .menu ul li {
//  float: left;
//  margin: 0 10px;
//  line-height: 40px;
//}
//
//header .menu ul li a {
//  color: #2c374c;
//  text-decoration: none;
//  text-transform: uppercase;
//  font-size: 14px;
//  z-index: 99999999;
//}
//
//header .menu ul li.button a {
//  border-radius: 3px;
//  border: 1px solid #2c374c;
//  line-height: 20px;
//  padding: 10px;
//}
//
//header.black .menu ul li a {
//  color: #fff;
//  text-decoration: none;
//  text-transform: uppercase;
//  font-size: 14px;
//}
//
//header.black .menu ul li.button a {
//  border-radius: 3px;
//  border: 1px solid #fff;
//  line-height: 20px;
//  padding: 10px;
//}
//
//.rubriques {
//  width: 100%;
//  height: 60px;
//  background: #2c374c;
//  border-bottom: 3px solid #777777;
//  margin-bottom: 20px;
//}
//
//.rubriques .container {
//  width: 1024px;
//  margin: auto;
//}
//
//.rubriques .container ul {
//  list-style: none;
//}
//
//.rubriques .container ul li {
//  float: left;
//  margin-right: 5px;
//}
//
//.rubriques .container ul li a {
//  line-height: 50px;
//  padding: 5px 15px;
//  color: #fff;
//  text-decoration: none;
//  display: block;
//  -webkit-transition: .5s;
//  transition: .5s;
//}
//
//.rubriques .container ul li a:hover {
//  padding: 10px 15px 0;
//  background: #777777;
//}
//
//.input-block {
//  display: flex;
//  flex-wrap: wrap;
//  width: 100%;
//}
//
//.subtitle {
//  padding-top: 15px;
//  margin-bottom: 15px;
//}
//
//.seo-container {
//  width: 100%;
//  display: flex;
//  .input {
//    width: 100%;
//  }
//  &>div:first-child {
//    width: 50%;
//  }
//  &>div:nth-child(2) {
//    width: 50%;
//  }
//}
//
//.long {
//  width: 100% !important;
//}
//
//input[type=number], input[type=text], input[type=password] {
//  width: 100%;
//  font-size: 16px;
//  color: #222;
//  border: 0;
//  padding: 10px 15px;
//  border-radius: 2px;
//  box-sizing: padding-box;
//  outline: none;
//}
//
//footer {
//  height: 40px;
//  margin: 10px 0;
//  background: #F6f6f6;
//  width: 1024px;
//  margin: auto;
//}
//
//footer .copyright {
//  float: left;
//  line-height: 40px;
//  color: #555;
//  font-size: 12px;
//  width: 250px;
//}
//
//.no-padding {
//  padding: 0 !important;
//}
