header.header {
  position: relative;
  z-index: 1;
  @include flex-center-sb;
  height: 64px;
  padding: 0 16px;
  margin: 0;
  box-shadow: 0 1px 2px $shadow-color4;
  background-color: $white;
}

.logo-wrapper {
  @include flex-center-left;
  display: inline-flex;

  a {
    @include flex-center;
  }
}