@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/functions";

.categoryHolder {
  &:not(:last-of-type) {
    margin: rem-calc(0 0 30);
  }
  & > .ttl {
    margin: rem-calc(0 0 24);
    font-size: rem-calc(18);
    line-height: rem-calc(29);
    font-weight: $weight-semibold;
  }
}

.folders {
  @include flex-start-left;
  flex-wrap: wrap;
  margin-right: rem-calc(0 -32 0 0);
  width: calc(100% + 32px);
  overflow: auto;

  .folder {
    @include flex-center-left;
    width: calc(100% / 3);
    max-width: 320px;
    min-width: 280px;
    padding: rem-calc(10 16);
    margin: rem-calc(0 32 16 0);
    border: 1px solid $border-color3;
    border-radius: rem-calc(3);
    box-shadow: 0 1px 2px 0 $shadow-color4;
    background-color: $white;

    .iconWrapper {
      width: rem-calc(32);
      height: rem-calc(32);
      margin-right: rem-calc(8);
    }

    .icon {
      font-size: rem-calc(24);
    }

    .ttl, .descr {
      margin: 0;
    }

    .ttl {
      font-size: rem-calc(16);
      line-height: rem-calc(25);
      font-weight: $weight-semibold;
    }

    .descr {
      font-size: rem-calc(11);
      line-height: rem-calc(17);
      font-weight: $weight-normal;
    }
  }
}

.icon {
  font-size: rem-calc(24);
}
