.data-table {
  max-width: 100%;
  width: 100%;
  overflow: auto;

  .table {
    font-family: inherit;

    tr {
      th, td {
        text-align: left;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        color: $text-color;

        .btn-wrapper {
          //margin-top: rem-calc(8);
          @include flex-center-left;
          flex-flow: wrap;

          .btn {
            margin: rem-calc(0 0 8);

            &:not(:last-of-type) {
              margin: rem-calc(0 8 8 0);
            }
          }
        }
      }
    }

    .tag-row {
      min-width: rem-calc(105);
    }

    .thead {
      background-color: $bg-gray;
      box-shadow: inset 0 -1px 0 0 $shadow-color, inset 0 1px 0 0 $shadow-color;

      tr {
        height: rem-calc(46);

        th, td {
          border: none;
          font-size: rem-calc(12);
          line-height: rem-calc(15);
          font-weight: $weight-semibold;
          color: $ttl-color;
          text-transform: uppercase;
        }

        span {
          @include flex-center-left;
        }
      }
    }

    .tbody {
      background-color: $white;

      tr {
        td, th {
          padding-top: rem-calc(12);
          padding-bottom: rem-calc(18);
          vertical-align: baseline;
        }
      }
    }

    .italic-text {
      opacity: 0.7;
      font-style: italic;
    }

    .small-text {
      font-size: rem-calc(12);
      line-height: rem-calc(18);
    }

    .big-text {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
    }
  }
}

.detail-table-container {
  padding: rem-calc(32 42 5 44);
  border-radius: rem-calc(3);
  background-color: $white;
  box-shadow: inset 0 -1px 0 0 $shadow-color;

  .table-ttl {
    margin: rem-calc(0 0 22);
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    font-weight: $weight-bold;
    color: $text-color;
  }

  .detail-table {
    tr {
      td,th {
        vertical-align: top;
        min-width: 156px;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        color: $text-color;
        padding: rem-calc(4 0);

        &:not(:last-of-type) {
          padding: rem-calc(4 32 4 0);
        }

        &:first-of-type {
          font-weight: $weight-medium;
        }

        .flex-wrapper {
          @include flex-center-sb;

          .text {
            display: flex;
            width: 100%;
            max-width: 130px;
          }

          .iconWrapper {
            width: rem-calc(16);
            height: rem-calc(16);
            flex-shrink: 0;
            cursor: pointer;
            margin-left: rem-calc(50);

            &:hover {
              .icon {
                fill: $active-color;
              }
            }

            .icon {
              font-size: rem-calc(14);
            }
          }
        }
      }
    }
  }
}