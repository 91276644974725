.link-item-cta {
  padding: 8px;
  background: #F6F6F6;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  color: #777;
  border: 2px solid #EEE;
  user-select: none;
  cursor: pointer;
  transition: .2s;
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
  line-height: 18px;
  margin-top: 24px;

  &:before {
    content: "+";
    font-size: 18px;
    margin-right: 8px;
    line-height: 18px;
    border-radius: 118px;
    border: 1px solid;
    display: inline-block;
    width: 18px;
  }

  &:hover {
    color: #3392FF;
    border-color: #3392FF;
    background: rgba(51, 146, 255, 0.1)
  }
}


.linked-item-wrapper {
  background: #F6F6F6;
  border-radius: 4px;
  border: 1px solid #EEE;
  margin-top: 16px;


  .lv-item {
    padding: 8px;
    display: flex;

    label {
      font-weight: bold;
      font-size: 14px;
      margin-right: 8px;
    }

    .lp-select .dropdown {
      min-width: initial;
    }

    > div {
      display: flex;

      .custom-input {
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
          margin-left: 4px;
        }

        > div {
          margin: 0;
        }
      }
    }
  }

  .lv-footer {
    background: #EEE;
    border-radius: 0 0 4px 4px;
    padding: 4px 8px;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    .btn-icon {
      overflow: hidden;

      .iconWrapper .del-ico {
        font-size: 2.1875rem;
        margin-bottom: -1.3rem;
        margin-left: 1.3rem;
      }

      &:hover .iconWrapper .del-ico path {
        fill: #e32778
      }
    }
  }
}