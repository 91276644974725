form {
  width: 100%;

  .form-ttl {
    margin: rem-calc(0 0 32);
    font-size: rem-calc(16);
    line-height: rem-calc(25);
    font-weight: $weight-normal;
    color: $menu-color;
  }

  .form-field {
    position: relative;

    .label-wrapper {
      @include flex-start-left;
      flex-flow: column;

      input {
        width: 100%;
        padding: rem-calc(11.5 15.5 10.5 15.5);
        border: 1px solid $icon-gray-light;
        border-radius: rem-calc(3);
        background-color: $white;
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.16);
        color: $dark;
        transition: boder-color $animation-speed, background-color $animation-speed, color $animation-speed;

        &::placeholder {
          color: $placeholder-color;
        }

        &:focus {
          border: 1px solid $active-color3;
        }

        &[readonly] {
          background-color: $bg-gray2;
          border: 1px solid $bg-gray2;
          color: $placeholder-color;
        }
      }
    }

    .text, .text-error {
      margin: 0;
      font-size: rem-calc(12);
      line-height: rem-calc(16);
      font-weight: $weight-normal;
    }

    .text {
      margin-bottom: rem-calc(7);
      color: $label-color;
    }

    .text-error {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(0, 100%);
    }
  }

  .nice-select-container {
    width: 100%;
    cursor: pointer !important;

    .nice__control {
      border: 1px solid $icon-gray-light;
      border-radius: 3px;
      background-color: $white;
      cursor: pointer;

      .nice__value-container {
        padding: rem-calc(4 16);

        .nice__single-value {
          font-size: rem-calc(14);
          line-height: rem-calc(18);
          color: rgba(37, 155, 244, 0.92);
        }
      }

      .nice__indicator-separator {
        display: none;
      }
    }

    .nice__menu {
      .nice__menu-list {
        .nice__option {
          &.nice__option--is-selected {

          }

          &.nice__option--is-focused {

          }
        }
      }
    }
  }
}

.detail-settings {
  form {
    @include flex-center-left;
    width: 100%;
    padding: rem-calc(21 24);
    margin: rem-calc(0 0 26);
    border: 1px solid $shadow-color;
    border-radius: 3px;
    background-color: $white;
    box-shadow: 0 1px 2px 0 $shadow-color4;

    .inner-container {
      flex: 1;
      @include flex-center-left;
    }

    .btn-wrapper {
      justify-self: flex-end;
      flex-shrink: 0;
    }

    .btn {
      min-width: 109px;
      padding: rem-calc(8 8);
      flex-shrink: 0;
    }

    .form-field {
      width: 100%;
      min-width: 129px;
      margin: rem-calc(0 31 0 0);

      input {
        width: 100%;
        border: 1px solid $icon-gray-light;
        border-radius: 3px;
        background-color: $white;
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        color: rgba(37, 155, 244, 0.92);
      }
    }

    .fieldName {
      max-width: 385px;
    }

    .fieldCategory {
      max-width: 233px;
    }

    .fieldDocs {
      max-width: 129px;
    }

    .fieldType {
      max-width: 129px;
    }

    .fieldModel {
      max-width: 129px;
    }
  }
}

.logFormContainer {
  form {
    .form-ttl {
      margin: rem-calc(0 0 40);
      font-size: rem-calc(14);
      line-height: rem-calc(18);
      color: $ttl-color;
    }

    .form-footer {
      @include flex-center-sb;
      margin: rem-calc(23 0 15);
      padding-bottom: rem-calc(14);
      border-bottom: 1px solid $border-color4;
    }
  }
}

.forgotFormContainer, .resetFormContainer {
  @extend .logFormContainer;

  form {
    .form-footer {
      padding-bottom: rem-calc(39);
    }
  }

  .success-massage {
    padding-top: rem-calc(56);
    padding-bottom: rem-calc(70);
    margin: rem-calc(0 0 15);
    border-bottom: 1px solid $border-color4;

    p {
      margin: rem-calc(0 0 16);
      font-size: rem-calc(14);
      line-height: rem-calc(22);
      color: $ttl-color;
    }

    .icon-block {
      @include flex-center;
      margin: rem-calc(0 0 9);
    }

    .iconWrapper {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: $success;
    }

    .icon {
      font-size: rem-calc(14);
      fill: $white;
    }
  }
}