.data-pagination {
  @include flex-center-sb;
  position: relative;

  .total {
    position: absolute;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: rem-calc(14);
    line-height: rem-calc(21);
    text-align: left;
  }

  ul {
    @include clear-list;
    @include flex-center;
    max-width: calc(100% - 200px);
  }

  a {
    //@include clear-btn-input;
    @include flex-center;
    position: relative;
    padding: rem-calc(12 13);
    font-size: rem-calc(14);
    line-height: rem-calc(18);
    text-align: center;
    transition: color $animation-speed;

    &:hover {
      color: $active-color2;

      &::after {
        opacity: 0.5;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 4px;
      transform: translate(0, 50%);
      background-color: $active-color2;
      opacity: 0;
      transition: opacity $animation-speed;
    }
  }

  li {
    @include flex-center;

    &:not(:nth-last-of-type(2)) {
      margin-right: rem-calc(15);
    }

    .icon {
      fill: $menu-color;
      font-size: rem-calc(10);
    }

    &.previous, &.next {
      a {
        &:hover {
          .icon {
            fill: $active-color2;
          }
        }

        &::after {
          content: none;
        }
      }
    }

    &.previous {
      margin: rem-calc(0 10 0 0);
    }

    &.next {
      margin: rem-calc(0 0 0 10);
    }

    &.selected {
      a {
        &::after {
          opacity: 1;
        }
      }
    }

    &.break {
      margin-left: rem-calc(-15);
      overflow: hidden;
      width: 0;
      height: 0;
      visibility: hidden;
    }
  }
}