.container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: calc(100% - 64px);
}

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  //background: #F6F9FC;
}

.container-inner {
  margin-left: 264px;
  width: 100%;
  overflow-y: hidden;
  background-color: $bg-gray3;

  .content {
    .padding-container {
      padding: rem-calc(0 32);
    }

    .ttl-padding-container {
      padding-top: rem-calc(20);
      padding-bottom: rem-calc(0);
      background: #F6F9FC;
    }

    .pagination-padding-container {
      padding-top: rem-calc(40);
      padding-bottom: rem-calc(23);
    }

    .ttl-container {
      @include flex-center-sb;
      margin: rem-calc(0 0 37.5);
    }

    .bread-crumbs-container {
      @include flex-start-left;

      .padding-container {
        padding: rem-calc(10 16 0 32);
      }
    }
  }

  .fileDetailPage {
    @include flex-stretch-left;

    .bread-crumbs-container {
      flex-shrink: 0;
    }

    .ttl-container {
      margin: rem-calc(0 0 27);
    }

    .detail-container {
      width: 100%;

      .grid-container {
        @include flex-start-left;
        flex-flow: wrap;

        .grid-item {
          margin: rem-calc(0 120 30 0);
        }
      }
    }

    .detail-table-container, .detail-links-container {
      display: inline-flex;
      flex-flow: column;
      min-width: 320px;
    }
  }

  .productsDetailPage {
    @include flex-start-left;
    flex-flow: column;

    > * {
      width: 100%;
    }

    .flex-row {
      @include flex-center-left;
      flex-basis: auto;

      .row-item {
        @include flex-center-sb;
        width: 100%;
      }

      .bread-crumbs-container {
        width: auto;
        flex-shrink: 0;
      }
    }

    .btn-wrapper {
      .btn-icon-text {
        margin: 0 !important;

        &:not(:last-of-type) {
          margin: rem-calc(0 8 0 0) !important;
        }
      }
    }
  }

  .newFilePageContainer {
    .nfTtl {
      @include flex-center-left;
      margin: rem-calc(0 0 25);

      .ttl-container, .data-search {
        display: inline-flex;
      }

      .ttl-container {
        margin: rem-calc(0 38 0 0);
      }

      .data-search {
        min-width: 320px;

        .field-holder {
          width: 100%;
        }
      }
    }
  }

  .accountPageContainer, .settingsPageContainer {
    .ttl-container {
      margin: rem-calc(0 0 33);
    }
  }

  .grid-container-form {
    @include flex-stretch-left;
    flex-flow: wrap;

    .grid-item {
      //@include flex-start-left;
      //flex-flow: column;
      //max-width: 320px;
      //width: 100%;
      //margin: rem-calc(0 120 30 0);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: column;
      max-width: 414px;
      width: 100%;
      margin: 0 4rem 1.875rem 0;
    }
  }
}

.loginContainer {
  width: 352px;
  margin: 0 auto;
  padding: rem-calc(0 16);

  .ttl-padding-container {
    padding-top: rem-calc(52);
    padding-bottom: rem-calc(40);
  }
}

.forgotContainer, .resetContainer {
  @extend .loginContainer;
}
