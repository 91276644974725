.content {
  .ttl-container {
    .ttl {
      margin: 0;
      font-size: rem-calc(36);
      line-height: rem-calc(58);
      font-family: "Circular - Bold";
      color: #051259;
      //font-weight: $weight-normal;
      font-weight: bold;
    }

    .sub-ttl {
      margin: rem-calc(0 0 0 16);
      font-size: rem-calc(12);
      line-height: rem-calc(15);
      font-weight: $weight-semibold;
      color: $ttl-color;
      opacity: 0.51;
    }
  }

  .bread-crumbs-container {
    .go-back-ttl {
      font-size: rem-calc(18);
      line-height: rem-calc(29);
      padding: 12px 24px;
      font-weight: $weight-semibold;
      color: $menu-color;
      text-decoration: underline;
    }
  }
}

.loginContainer {
  .footer-link {
    font-size: rem-calc(14);
    line-height: rem-calc(21);
    text-align: center;
    color: $text-color
  }
}

.forgotContainer, .resetContainer {
  @extend .loginContainer;
}
