@mixin center($top: 50%, $left: 50%) {
  position: absolute;
  top: $top;
  left: $left;
}

@mixin abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flex($align-items, $justify-content) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin clear-btn-input {
  border: none;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  &:hover, &:focus {
    outline: none;
    box-shadow: none;
  }
}

@mixin clear-list {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}

//   flex-baseline   (align-items:baseline)

@mixin flex-baseline {
  display: flex;
  align-items: baseline;
}

@mixin flex-baseline-right {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

@mixin flex-baseline-left {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

@mixin flex-baseline-sb {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

@mixin flex-baseline-sa {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
}

//   flex-center   (align-items:center)

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-center-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-center-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center-sa {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

//   flex-start   (align-items:flex-start)

@mixin flex-start-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@mixin flex-start-right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@mixin flex-start-left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin flex-start-sb {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@mixin flex-start-sa {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

//   flex-end   (align-items:flex-end)

@mixin flex-end-center {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@mixin flex-end-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@mixin flex-end-left {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

@mixin flex-end-sb {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

@mixin flex-end-sa {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}

//   flex-stretch   (align-items:stretch)

@mixin flex-stretch-center {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

@mixin flex-stretch-right {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

@mixin flex-stretch-left {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

@mixin flex-stretch-sb {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

@mixin flex-stretch-sa {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
}

/// Animate css properties
// usage
// scss - @include animate(color);
//     or @include animate(color width);
//     or @include animate(color width, 1s, linear);
//
$animation-speed: .3s !default;

@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
  $list:();
  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: join($list, #{$str}, comma);
  }
  transition: $list;
}