$mobile: 360px;
$tablet: 768px;
$desktop: 1200px;

$white: #ffffff;
$black: #000000;
$dark: #212529;

$text-color: #152935;
$ttl-color: #323648;
$menu-color: #171717;
$label-color: #565656;
$placeholder-color: #8897A2;

$bg-main: #f8f9fa;
$bg-gray: #f5f5f5;
$bg-gray2: #f3f3f3;
$bg-gray3: #fafafa;

$shadow-color: #e1e1e1;
$shadow-color2: #e0e0e0;
$shadow-color3: rgba(224, 224, 224, 0.5);
$shadow-color4: rgba(0, 0, 0, 0.1);
$shadow-color-active: #2182cc;

$border-color: #dadada;
$border-color2: #e5e5e5;
$border-color3: #e1e1e1;
$border-color4: #e9ecef;


$icon-gray: #495057;
$icon-gray-light: #dee2e6;

$active-color: #3392ff;
$active-color2: #259bf4;
$active-color3: rgba(37, 155, 244, 0.92);

$success: #14cf96;

//custom colors
$black-100: #343a40;
$pink: #e32778;
$pink-100: #bd2064;
$pink-200: #d70e76;
$gray: #fcfcfc;
$gray-100: #f8f9fa;
$gray-200: #bec0c1;
$gray-300: #868e96;
$gray-400: #adb5bd;
$gray-500: #f1f3f5;
$gray-600: #ccc;
$gray-700: #ced4da;
$gray-800: #979797;
$blue: #3392FF;
$dark-blue: #1954B7;
$blue-100: #66d9e8;
$green-100: #8ce99a;
$green-200: #51cf66;
$green-300: #329d45;
$violet-100: #845ef7;
$violet-200: #9013fe;
$red: #fa5252;
$orange: #fd7e14;


$animation-speed: 0.3s;


$default-font-family: 'Inter';
$default-font-size: 16px;
$default-line-height: 1.5;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;