.editor-popover-x-axis {
  position: absolute;
  height: 300px;
  width: 420px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  top: -105px;
  left: -200px;
}

.editor-popover-y-axis {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 420px;
  height: 200px
}

.editor-popover {
  position: sticky;
  bottom: 10px;
  right: 10px;
  background: #FFF;
  width: 220px;
  height: 200px;
  border-radius: 3px;
  z-index: 1;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.variable-picker {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
  width: 100%;
  align-items: stretch;

  .searchbar {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 3px;

    input {
      border: 1px solid #CCC;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
      outline: none;
      width: 100%;
      box-sizing: border-box;
      font-size: 12px;
      color: #333;
      border-radius: 3px;
      height: 24px;
      padding: 0 4px;

      &:focus {
        border: 1px solid #3392ff;
      }
    }
  }

  .results {
    min-height: 40px;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    text-align: left;
    font-weight:normal;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        width: 100%;
        white-space: pre-wrap;
        font-size: 12px;
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        cursor: pointer;

        &:nth-child(even) {
          background: #F3F3F3;
        }

        &:hover {
          background: #D6F0FF;
        }

        .id {
          color: #888;
          padding-right: 4px;
          margin-right: 4px;
          font-size: 12px;
          text-align: left;
          font-weight: bold;
          width: 28px;
          flex-shrink: 0;
        }

        &.active {
          background: #3392FF;
          color: #FFF;

          .id {
            color: #F6F6F6;
          }
        }

      }
    }
  }

  .footer {
    font-size: 9px;
    color: #666;
    text-align: center;
    padding: 3px;
    background: #F6F6F6;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 0 0 3px 3px;
    border-top: 1px solid #CCC;
    box-sizing: border-box;
    user-select: none;
  }


  .variable-parent-tree {
    position: absolute;
    top: 0;
    left: 100%;
    width: 300px;
    background: #FFF;
    z-index: 999999;
    border-radius: 4px;
    box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.3);
    margin-left: 10px;
    padding: 10px;

    &.pull-left {
      left: -310px;
      margin-left: 0;
    }

    .parentTree {
      ul {
        list-style: none;
        margin: 0;
        padding-left: 0;

        li {
          padding: 8px 0px 0px 10px;
          position: relative;

          .level {
            margin-left: 10px;
            font-size: 12px;
            display: flex;

            .id {
              font-weight: bold;
              color: #a2a5b5;
              margin-right: 4px;
            }

            .label {
              font-weight: 500;
            }
          }

          &:before {
            content: "";
            position: absolute;
            top: -13px;
            left: 1px;
            border-left: 2px solid #a2a5b5;
            width: 1px;
            height: 100%;
          }

          &:after {
            content: "";
            position: absolute;
            border-top: 2px solid #a2a5b5;
            top: 17px;
            left: 1px;
            width: 16px;
          }

          &:last-child:before {
            top: -9px;
            height: 28px;
          }
        }
      }

      >ul {
        padding: 0;
      }

      >ul>li {
        padding: 0;

        >.level {
          margin-left: -2px;
        }

        &:after,
        &::before {
          display: none;
        }

        >ul>li:last-child:before {
          top: -2px;
          height: 20px;
        }

      }
    }
  }
}