@keyframes selectDropDownSlideUp {
  from {
    opacity: 0;
    margin-top: 10px;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}

.lp-select {
  background: #FFF;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
  box-sizing: border-box;
  line-height: 24px;
  height: 40px;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
  position: relative;

  &.disabled {
    cursor: initial;
  }

  >.icon {
    position: absolute;
    right: 4px;
    top: 4px;
    background: white;
    background: linear-gradient(90deg, transparent 0%, white 27%);
    height: 30px;
    width: 37px;
    text-align: right;

    svg {
      width: 30px;
      height: 30px;

      * {
        stroke: #DEE2E6
      }
    }
  }

  .current-value {
    color: #43536C;
    line-height: 24px;
    overflow: hidden;
    max-height: 24px;
    word-break: break-all;
  }

  .dropdown {
    position: absolute;
    background: #FFF;
    z-index: 9999999;
    list-style: none;
    border-radius: 4px;
    max-height: 200px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);
    top: 40px;
    width: 100%;
    min-width: 400px;
    left: 0;

    &:after {
      position: absolute;
      width: 100%;
      height: 20px;
      background: transparent;
      content: "";
      top: -20px;
      left: 0;
    }

    &:before {
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      content: "";
      background: #FFF;
      top: -5px;
      left: 10px;
      position: absolute;
      z-index: -1;
    }

    .filter {
      width: 100%;
      height: 40px;
      padding: 5px;
      box-sizing: border-box;
    }

    .filter input[type="text"] {
      width: 100%;
      box-sizing: border-box;
      height: 30px;
      border: 1px solid #CCC;
      border-radius: 3px;
      padding: 0 10px;
    }

    ul {
      overflow-y: auto;
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      max-height: 160px;

      li {
        padding: 10px;
        font-size: 12px;
        cursor: pointer;
        padding-right: 20px;

        &:hover,
        &.active {
          background: #F6F6F6
        }

        &.disabled {
          color: #666;
          cursor: default;

          &:hover {
            background: #FFF;
          }
        }

        &.highlight {
          background: #ffffd8 !important;
        }

        &:last-child {
          border-radius: 0 0 4px 4px;
        }
      }

      &:hover {
        li.active:not(:hover) {
          background: initial;
        }
      }
    }
  }

  &:hover:not(.disabled),
  &.active {
    padding: 7px;
    border: 2px solid #3392FF;

    .dropdown {
      visibility: visible;
      opacity: 1;
      animation: ease-in selectDropDownSlideUp .1s;
    }

    >.icon {
      right: 3px;
      top: 3px;
      height: 30px;

      svg {
        * {
          stroke: #3392FF
        }
      }
    }
  }
}

.pagination {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  BORDER-RADIUS: 3PX;
  PADDING: 3px;
  line-height: 15PX;
  user-select: none;
  top: 9px;

  .current {
    font-size: 11px;
    font-weight: BOLD;
    margin: 0 4px;
    color: #333;
  }

  button {
    padding: 3px;
    margin: 0;
    color: #666600;
    font-weight: bold;
    font-size: 12px;
    background: #ffffc2;
    border-radius: 3px;

    &[disabled] {
      opacity: 0.6
    }

    &:first-child {
      margin-right: 4px;
      border-radius: 3px 0 0 3px
    }

    &::last-child {
      border-radius: 0 3px 3px 0
    }
  }
}